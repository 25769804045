<template>
    <footer class="footer">
        <div class="container">
            <h2>Cannot attend IBA this year?</h2>
            <div class="columns">
                <div class="footer__content column">
                    <p>Do not worry, stay up to date, by following our social media channels and checking regularly on SSW insight</p>
                    <a href="https://ssw.solutions/en/" target="_blank">www.ssw.solutions</a>
                </div>
            </div>
            <div class="footer__social-box">
                <a class="footer__icon-box" href="https://www.linkedin.com/company/1356327/" target="_blank">
                    <i aria-hidden="true" class="fa fa-linkedin-square"></i>
                </a>
                <a class="footer__icon-box" href="https://www.facebook.com/SSW-Pragmatic-Solutions-198477040912548/" target="_blank">
                    <i aria-hidden="true" class="fa fa-facebook-square"></i>
                </a>
                <a class="footer__icon-box" href="https://twitter.com/PragmaticSsw" target="_blank">
                    <i aria-hidden="true" class="fa fa-twitter-square"></i>
                </a>
                <a class="footer__icon-box" href="https://www.youtube.com/channel/UCJSyuGORhen_7VBZ2pC7h5Q" target="_blank">
                    <i aria-hidden="true" class="fa fa-youtube-play"></i>
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "CannotAttend",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.footer {
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1a2c70;
    color: white;
    text-align: center;
    @include touch {
        padding: 30px;
    }
    & h2 {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 30px;
    }
    &__content {
        /deep/ & p {
            font-weight: bold;
            margin-bottom: 20px;
        }
        /deep/ & a {
            color: #ee3ea9;
            transition: color .3s ease-in-out;
            &:hover {
                color: white;
            }
        }
    }
    &__social-box {
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: center;
    }
    &__icon-box {
        margin: 10px;
        color: $white;
        & i {
            color: $white;
            font-size: 30px;
        }
    }
}
</style>