<template>
    <section class="practices">
        <div class="container">
            <div class="columns">
                <div class="column has-text-centered">
                    <h2>{{ data.title }}</h2>
                </div>
            </div>
            <div class="practices__container columns">
                <a class="practices__href-box" v-for="(practice, key) in data.practices" :key="key" :href="practice.href" target="_blank">
                    <div class="practices__single-box">
                        <img :src="practice.image.url" :alt="practice.image.alt">
                        <h3>{{ practice.name }}</h3>
                    </div>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Practices",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.practices {
    background-color: $blue;
    padding: 30px 0 60px;
    & h2 {
        color: $white;
        font-size: $heading;
        font-weight: bold;
        margin-bottom: 20px;
        font-family: 'Gilroy Bold'
    }
    &__container {
        display: flex;
        justify-content: center;
        @include touch {
            flex-direction: column;
            align-items: center;
        }
    }
    &__href-box {
        margin: 0 10px;
        @include touch {
            margin-bottom: 20px;
        }
    }
    &__single-box {
        background-color: $white;
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $blue;
        @include touch {
            max-width: 300px;
        }
        &:not(:last-child) {
            margin-right: 20px;
            @include touch {
                margin-bottom: 20px;
                margin-right: 0px;
            }
        }
        & h3 {
            font-weight: bold;
            font-size: $sub-heading;
            font-family: 'Gilroy Bold'
        }
    }
}
</style>