<template >
    <section class="video">
        <div class="video__content">
            <a href="https://ssw.solutions/whypoland/" target="_blank">
                <video class="intro__video" playsinline loop preload autoplay muted width='100%' height='100%'>
                    <source :src="data.video" type="video/mp4">
                </video>
            </a>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.video {
    margin-top: 6px;
    &,
    &__content {
        display: flex;
        box-sizing: border-box;
        width: 100%;
    }
}
</style>