<template>
    <section class="our-values has-text-centered">
        <h2>{{ data.title }}</h2>
        <div class="container">
            <div class="columns">
                <div v-for="(singleValue, key) in data.value" :key="key" class="our-values__single column is-6 has-text-left">
                    <div class="our-values__image-box">
                        <img :src="singleValue.image.url" :alt="singleValue.image.alt">
                    </div>
                    <div class="our-values__text-box">
                        <h3 class="our-values__name">{{ singleValue.title }}</h3>
                        <article>
                            <p>{{ singleValue.content }}</p>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:"OurValues",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.our-values {
    padding: 50px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    & .columns {
        display: flex;
        flex-wrap: wrap;
    }
    & h2 {
        color: $dark-blue;
        font-weight: bold;
        font-size: $heading;
        margin-bottom: 40px;
        font-family: 'Gilroy Bold'
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        @include mobile {
            flex-wrap: nowrap;
            flex-direction: column;
        }
    }
    &__single {
        display: flex;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
        justify-content: center;
        @include touch {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        @include mobile {
            width: 100% !important;
            padding: 0;
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
    }
    &__image-box {
        margin-right: 20px;
        display: flex;
        align-items: center;
        & img {
            max-width: 150px;
            @include touch {
                margin-bottom: 20px;
            }
        }
    }
    &__text-box {
        max-width: 450px;
    }
    &__name {
        margin-bottom: 5px;
        color: $pink;
        font-size: $sub-heading;
    }
}
</style>