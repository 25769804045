<template>
    <section class="section contact" :style="{ backgroundImage: 'url(' + data.background.url + ')' }">
        <div class="container contact__padding">
            <div class="columns">
                <div class="contact__left column is-7">
                    <div
                        class="contact__left-content is-size-3"
                        v-html="data.left_side_text"
                    ></div>
                    <div class="contact__logo">
                        <img :src="data.left_side_logo.url" alt="IBA Logo">
                    </div>
                </div>
                <div class="contact__right column is-5">
                    <div
                        class="contact__right-content is-size-5"
                        v-html="data.right_side_text"
                    ></div>
                    <div class="contact__form">
                        <form @submit.prevent="onSubmit">
                            <div class="columns">
                                <div class="contact__form-left column is-6">
                                    <input type="text" id="name" name="name" placeholder="Name" v-model="name">
                                    <input type="text" id="surname" name="surname" placeholder="Surname" v-model="surname">
                                    <input type="text" id="company" name="company" placeholder="Company name" v-model="company">
                                    <vue-recaptcha
                                        ref="recaptcha"
                                        @verify="onVerify"
                                        @expired="onExpired"
                                        size="invisible"
                                        :sitekey="sitekey"
                                    >
                                    </vue-recaptcha>
                                </div>
                                <div class="contact__form-right column is-6">
                                    <input type="text" id="mobile" name="mobile" placeholder="Mobile" v-model="mobile">
                                    <input type="email" id="email" name="email" placeholder="E-mail address" v-model="email">
                                    <input type="text" id="linkedin" name="linkedin" placeholder="Linkedin profile" v-model="linkedin">
                                    <input type="submit" name="submit" :value="sendLabel">
                                </div>
                            </div>
                        </form>
                    </div>
                    <transition name="fade" mode="out-in">
                        <div v-if="infoBox.type != 0" class="msg">
                            <span
                                :class="{'msg__green':infoBox.type == 1,'msg__red':infoBox.type == 2}"
                            >{{ infoBox.content }}</span>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'
export default {
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    data(){
        return {
            name: '',
            surname: '',
            email: '',
            company: '',
            mobile: '',
            linkedin: '',
            sendLabel:  'Send',
            infoBox: {
                type: 0, // 0 - no, 1 - success, 2 - error
                content: ''
            },
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
        }
    },
    props:{
        data: Object,
        printClass: Function,
        className: String,
    },
    methods: {
        onSubmit(){
            this.$refs.recaptcha.execute()
            this.sendLabel = 'Sending...'
        },
        onVerify(recaptchaToken){
            this.$refs.recaptcha.reset();
            var bodyFormData = new FormData();
            bodyFormData.set('name', this.name);
            bodyFormData.set('surname', this.surname);
            bodyFormData.set('company', this.company);
            bodyFormData.set('mobile', this.mobile);
            bodyFormData.set('email', this.email);
            bodyFormData.set('linkedin', this.linkedin);
            bodyFormData.set('recaptchaToken', recaptchaToken);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const name = document.querySelector("#name").value;
            const surname = document.querySelector("#surname").value;
            const company = document.querySelector("#company").value;
            const mobile = document.querySelector("#mobile").value;
            const email = document.querySelector("#email").value;
            const linkedin = document.querySelector("#linkedin").value;

            if (name == '' || surname == '' || company == '' || mobile == '' || email == '' || linkedin == '') {
                this.sendLabel = 'Send'
                return;
            }

            axios.post( process.env.VUE_APP_API + 'wp-json/better-api/v1/send', bodyFormData, config).then((response) => {
                if(response.data == 'ok'){
                    this.infoBox.type = 1
                    this.infoBox.content = 'Email was send successfully.'
                    this.sendLabel = 'Send'
                    setTimeout(() => {
                        this.infoBox.type = 0
                    },5000)
                } else {
                    this.infoBox.type = 2
                    this.infoBox.content = 'Server side error'
                    this.sendLabel = 'Send'
                    setTimeout(() => {
                        this.infoBox.type = 0
                    },5000)
                }

            }).catch((err) => {
                this.infoBox.type = 2
                this.infoBox.content = 'Cannot send email, please try again later.'
                this.sendLabel = 'Send'
                setTimeout(() => {
                    this.infoBox.type = 0
                },5000)
                console.log(getErrorMessage(err));
                //helper to get a displayable message to the user
                function getErrorMessage(err) {
                    let responseBody;
                    responseBody = err.response;
                    if (!responseBody) {
                        responseBody = err;
                    } else {
                        responseBody = err.response.data || responseBody;
                    }
                    return responseBody.message || JSON.stringify(responseBody);
                }
            })
        },
        onExpired(){
            console.log('Expired')
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.msg{
    @include mobile{
        margin-top: 80px;
    }
    &__red{
        color: #db1313;
    }

    &__green{
        color: #41e26a;
    }
}


.contact{
    padding: 130px 0;
    background-color: black;
    background-size: cover;
    background-position: center;
    @include mobile{
        padding: 60px 0 130px 0;
    }
    &__left{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-content{
            color: #FFF;
            /deep/h1{
                color: $pink;
                font-size: 2.5rem;
                font-family: 'Gilroy Bold';
            }
        }
    }
    &__right{
        &-content{
            color: #FFF;
            /deep/h2{
                font-family: 'Gilroy Bold';
                font-size: 1.5rem;
                margin-bottom: 10px;
            }
        }
    }
    &__form{
        form{
            margin-top: 50px;
        }
        &-left, &-right{
            padding-top: 0;
            padding-bottom: 0;
            input[type=text], input[type=email]{
                width: 100%;
                font-size: 1rem;
                border: none;
                border-bottom: 1px solid #FFF;
                background-color: transparent;
                padding: 10px 0;
                color: #FFF;
                font-family: 'Gilroy';
                border-radius: 0;
                -webkit-appearance: none;
                &:first-child{
                    border-top: 1px solid #FFF;
                }
                &::placeholder{
                    color: #FFF;
                }
            }
            input[type=submit]{
                background-color: $pink;
                border: 0;
                padding: 12px 30px;
                color: #FFF;
                float: right;
                margin-top: 20px;
                font-size: 1rem;
                cursor: pointer;
                border-radius: 0;
                -webkit-appearance: none;
                &::after{
                    content: '';
                    clear: both;
                }
            }
        }
        &-right{
            input[type=text], input[type=email]{
                &:first-child{
                    @include mobile{
                        border-top: none;
                    }
                }
            }
        }
    }
    &__padding{
        @include tablet{
            padding: 0 50px;
        }
        @include mobile{
            padding: 0 40px;
        }
    }
    &__logo{
        @include mobile{
            margin: 50px 0;
        }
    }
}
.grecaptcha-badge{
    visibility: hidden
}
</style>
