<template>
    <section class="recent-projects has-text-centered">
        <div class="container">
            <h2>{{ data.title }}</h2>
            <div class="recent-projects__container columns">
                <single-project v-for="(project, key) in data.projects" :project="project" :key="key"></single-project>
            </div>
        </div>
    </section>
</template>

<script>
import SingleProject from "./SingleProject";

export default {
    name: "RecentProjects",
    data() {
        return{}
    },
    components: {
        "single-project": SingleProject
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.recent-projects {
    padding: 50px 0px 80px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background-color: $bg;
    @include mobile {
        padding: 50px 20px 80px;
        justify-content: center;
        align-items: center;
    }
    & h2 {
        font-weight: bold;
        font-size: $heading;
        margin-bottom: 30px;
        font-family: 'Gilroy Bold';
        color: $dark-blue;
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include touch {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 1px;
        }
    }
}
</style>