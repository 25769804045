<template>
    <header class="header container">
        <div class="header__logo-box">
            <a href="https://ssw.solutions/en/" target="_blank">
                <img class="header__logo" src="@/assets/ssw.jpg" alt="SSW logo">
            </a>
        </div>
        <div class="header__social-box">
            <a class="header__icon-box" href="https://www.linkedin.com/company/1356327/" target="_blank">
                <i aria-hidden="true" class="fa fa-linkedin-square"></i>
            </a>
            <a class="header__icon-box" href="https://www.facebook.com/SSW-Pragmatic-Solutions-198477040912548/" target="_blank">
                <i aria-hidden="true" class="fa fa-facebook-square"></i>
            </a>
            <a class="header__icon-box" href="https://twitter.com/PragmaticSsw" target="_blank">
                <i aria-hidden="true" class="fa fa-twitter-square"></i>
            </a>
            <a class="header__icon-box" href="https://www.youtube.com/channel/UCJSyuGORhen_7VBZ2pC7h5Q" target="_blank">
                <i aria-hidden="true" class="fa fa-youtube-play"></i>
            </a>
        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            burger: false,
            menuLang: false,
            activeSubmenu: 0
        }
    },
    created() {

    },
    methods: {
        langHomeUrl(home_url) {
            return this.publicPath + home_url
        },
        navigateTo(url){
            const publicPath = '/' + this.$store.getters.getPublicPath
            const locale = this.$store.getters.getLocale
            if(url != '#'){
             this.$router.push(url)
             this.burger = false
            }
        }
    },
    computed: {
        languages(){
            return this.$store.getters.getLanguages
        },
        options(){
            return this.$store.getters.getOptions
        },
        locale(){
            return this.$store.getters.getLocale
        },
        menuLocale(){
            return (this.locale != this.$store.getters.getOptions.default_language) ? this.locale+'/' : ''
        },
        publicPath(){
            return this.$store.getters.getPublicPath
        },
        mainMenu(){
            return this.$store.getters.getMainMenu
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.header {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    @include touch {
        padding: 40px 20px;
    }
    &__logo-box {
        border-right: 1px solid #dcdcdc;
        width: 120px;
    }
    &__social-box {
        display: flex;
        align-items: center;
    }
    &__icon-box {
        margin: 10px;
        color: $dark-blue;
        & i {
            color: $dark-blue;
            font-size: 30px;
        }
    }
}
</style>
