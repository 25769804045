<template>
    <section class="international-rankings">
        <div class="international-rankings__content container">
            <h2>{{ data.title }}</h2>
            <div class="columns">
                <div v-html="data.description" class="column is-12"></div>
            </div>
            <div class="international-rankings__awards-box columns">
                <div v-for="(award, key) in data.awards" :key="key" class="international-rankings__single-award">
                    <a :href="award.href" target="_blank">
                        <img :src="award.image.url" :alt="award.image.alt">
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "InternationalRankings",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.international-rankings{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background-color: $white;
    width: 100%;
    padding: 30px 0;
    @include touch {
        padding: 30px;
    }
    &__content {
        text-align: center;
        & h2 {
            color: $pink;
            font-weight: bold;
            font-size: $heading;
            margin-bottom: 20px;
            font-family: 'Gilroy Bold'
        }
        /deep/ & {
            color: #1a2c70;
            font-size: $sub-heading;
            font-weight: bold;
        }
    }
    &__awards-box {
        padding: 30px 0;
        display: flex;
        justify-content: center;
        @include touch {
            flex-direction: column;
            align-items: center;
        }
        & img {
            max-width: 200px;
            @include touch {
                max-width: unset;
            }
        }
    }
    &__single-award {
        border-right: 1px solid $border-color;
        display: flex;
        justify-content: center;
        @include touch {
            width: 350px;
            margin-bottom: 20px;
            border-left: 1px solid $border-color;
        }
        @include mobile {
            border: none;
        }
        &:first-child {
            border-left: 1px solid $border-color;
            border: none;
        }
    }
}
</style>