<template>
    <section class="social-responsibility">
        <div class="container">
            <h2>{{ data.title }}</h2>
            <div class="columns">
                <div v-html="data.content" class="social-responsibility__content column"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:"SocialResponsibility",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.social-responsibility {
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ee3ea9;
    color: white;
    text-align: center;
    @include touch {
        padding: 30px;
    }
    & h2 {
        font-weight: bold;
        font-size: $heading;
        margin-bottom: 30px;
        font-family: 'Gilroy Bold'
    }
    &__content {
        /deep/ & p {
            font-weight: bold;
            margin-bottom: 20px;
        }
        /deep/ & a {
            color: white;
            transition: color .3s ease-in-out;
            &:hover {
                color: #1a2c70;
            }
        }
    }
}
</style>