<template >
    <section class="representatives">
        <div class="representatives__blue-bar">
            <h2>{{ data.title }}</h2>
        </div>
        <div class="representatives__content">
            <div class="representatives__container container">
                <div class="columns">
                    <div class="representatives__person column is-4" v-for="(person, key) in data.persons" :key="key">
                        <div class="representatives__1-of-2">
                            <figure>
                                <a :href="person.href" target="_blank">
                                    <img :src="person.image.url" :alt="person.image.alt">
                                </a>
                            </figure>
                        </div>
                        <div class="representatives__1-of-2">
                            <h3 class="representatives__name">{{ person.name }}</h3>
                            <span class="representatives__position">{{ person.position }}</span>
                            <span class="representatives__specializations">{{ person.specializations }}</span>
                            <a class="representatives__icon" :href="person.mail"></a>
                            <a class="representatives__more" :href="person.href" target="_blank">{{ person.more_text }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Representatives",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.representatives {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    box-shadow: 0px 5px 5px 0px rgba(199,199,199,1);
    &__blue-bar {
        background-color: $dark-blue;
        color: $white;
        padding: 10px 0;
        box-sizing: border-box;
        text-align: center;
        & h2 {
            font-weight: bold;
            font-size: $heading;
            font-family: 'Gilroy Bold'
        }
    }
    &__content {
        background-color: $white;
        padding: 40px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        @include small-screen {
            padding: 40px 20px;
        }
    }
    &__container {
        @include touch {
            margin: 0;
            justify-content: flex-start;
        }
    }
    &__person {
        display: flex;
        @include small-screen {
            flex-direction: column;
        }
    }
    &__1-of-2 {
        width: 50%;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-between;
        @include small-screen {
            width: 100%;
        }
        &:last-child {
            padding-left: 20px;
            @include small-screen {
                padding-left: 0px;
                margin-top: 10px;
                justify-content: space-between;
                height: 100%;
            }
        }
        & img {
            @include touch {
                max-width: 240px;
            }
        }
    }
    &__name {
        font-weight: bold;
        color: $dark-blue;
        font-size: $sub-heading;
        font-family: 'Gilroy Bold'
    }
    &__position {
        font-size: $small-text;
        color: $pink;
    }
    &__specializations {
        color: $blue;
        font-size: $small-text;
        @include small-screen {
            min-height: 42px;
            max-width: 250px;
        }
    }
    &__icon {
        background-image: url(../../assets/contact.svg);
        padding: 12px;
        background-repeat: no-repeat;
        margin: 5px 0;
    }
    &__more {
        margin: 0;
        float: right;
        text-transform: uppercase;
        color: #717171;
        position: relative;
        display: flex;
        height: 1.25rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: color .3s ease-in-out;
        &:hover {
            color: $pink;
        }
        &::after {
            font-size: 1rem;
            content: "";
            line-height: 1;
            display: inline-block;
            background-image: url(../../assets/arrow.min.svg);
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
            margin-left: 10px;
            width: 1.5rem;
            height: 1.25rem;
            vertical-align: middle;
        }
    }
}
</style>