<template>
    <div id="app">
        <the-header/>
        <transition name="fade" mode="out-in">
            <router-view :key="$route.params.id" />
        </transition>
        <the-footer/>
    </div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '@/components/TheHeader.vue'
import AOS from 'aos'

export default {
    components: {
        'the-header': TheHeader,
        'the-footer': TheFooter
    },
    metaInfo() {
        return {
            titleTemplate: this.seoTitleTemplate,
            title: this.title,
            htmlAttrs: {
                lang: this.$store.getters.getLocale
            },
            meta: [
                { name: 'description', content: this.seoDescription },
                { name: 'robots', content: this.seoRobots }
            ],
            link: [
                { rel: 'icon', href: this.optionsFavicon }
            ]
        }
    },
    computed: {
        page() {
            let page = this.$store.getters.getPageByPath(this.$route.path)
            if(page === undefined){
                this.$router.push({name: 'error404'})
            } else {
                return this.$store.getters.getPageByPath(this.$route.path)
            }
        },
        title() {
            if(this.page !== undefined){
                return this.page.title
            } else {
                return ''
            }
        },
        seoTitleTemplate() {
            return this.page.seo_title != null ? this.page.seo_title : this.$store.getters.getSeoTitleTemplate
        },
        seoDescription() {
            return this.page.seo_description != null ? this.page.seo_description : ''
        },
        seoRobots() {
            return this.page.seo_no_index != null ? 'noindex,nofollow' : 'index,follow'
        },
        optionsFavicon(){
            return this.$store.getters.getOptionsFavicon
        }
    },
    created() {
        AOS.init();
    }
}
</script>

<style scoped lang="scss">
#app {
    overflow: hidden;
}
</style>
