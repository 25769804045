<template>
    <div class="project__single is-4-desktop is-6-tablet is-12-mobile">
        <div class="project__content-box tile" :class="{fliped: isFliped}">
            <div class="tile__front">
                <img class="tile__image" :src="project.image.url" :alt="project.image.alt" />
                <h3 class="tile__title">{{ project.title }}</h3>
                <a @click.prevent="isFliped = !isFliped" href="#" class="contact__more btn-more">{{ project.more }}</a>
            </div>
            <div class="project__text-box tile__back">
                <div class="tile__text-box" v-html="project.content"></div>
                <a @click.prevent="isFliped = !isFliped" href="#" class="contact__more btn-more">{{ project.less }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SingleProject",
    data() {
        return {
            isFliped: false
        }
    },
    props: {
        project: Object
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.project__single {
    padding: 0 !important;
    display: flex;
    width: 33.33333%;
    @include touch {
        width: 100%;
        margin-bottom: 20px;
    }
}
.tile {
    width: 100%;
    height: 100%;
    position: relative;
    height: 375px;
    border: 1px solid $border-color;
    min-width: 250px;
    @include widescreen-only {
        height: 400px;
    }
    @include touch {
        min-width: 400px;
    }
    @include mobile {
        max-width: 100%;
        min-width: unset;
        height: 450px;
    }
    &__front {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #fff;
        transform: rotateY(0deg);
        transition: transform .3s ease-in-out;
        backface-visibility: hidden;
        overflow: hidden;
        flex-direction: column;
        & .btn-more {
            color: $pink;
        }
    }
    &__image {
        height: 225px;
        width: 225px;
    }
    &__back {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
        box-sizing: border-box;
        padding: 1.5rem;
        background-color: $blue;
        color: $white;
        transform: rotateY(180deg);
        transition: transform .3s ease-in-out;
        backface-visibility: hidden;
        overflow: hidden;
        flex-direction: column;
        & .btn-more {
            margin-top: 1rem;
            color: $pink;
        }
    }
    &__title {
        font-weight: bold;
        font-size: $sub-heading;
        color: $dark-blue;
        font-family: 'Gilroy Bold';
        margin-top: 20px;
    }
    &__text-box {
        font-size: 14px;
    }
    &.fliped .tile__front {
        transform: rotateY(180deg);
    }
    &.fliped .tile__back {
        transform: rotateY(0deg);
    }
}
</style>