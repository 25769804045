<template>
    <section class="international-cooperation">
        <div class="international-cooperation__blue-bar">
            <h2>{{ data.title }}</h2>
        </div>
        <div class="international-cooperation__content container">
            <div class="columns">
                <div v-html="data.content" class="column"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "InternationalCooperation",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.international-cooperation {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background-color: $bg;
    &__blue-bar {
        background-color: $dark-blue;
        color: $white;
        padding: 10px 0;
        box-sizing: border-box;
        text-align: center;
        @include small-screen {
            padding: 10px 20px;
        }
        & h2 {
            font-weight: bold;
            font-size: $heading;
            font-family: 'Gilroy Bold'
        }
    }
    &__content {
        color: $dark-blue;
        font-size: $sub-heading;
        padding: 30px 0;
        text-align: center;
        @include small-screen {
            padding: 30px 20px;
        }
        /deep/ & p{
            font-weight: bold;
            font-size: $sub-heading;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}
</style>